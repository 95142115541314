import { useEffect, useState } from 'react';
import { DeviceSizes } from '../DeviceInformations';

export const useDeviceParameters = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTabletSmall, setIsTabletSmall] = useState(false);
  const [isTabletLarge, setIsTabletLarge] = useState(false);

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, [window.innerWidth, window.innerHeight]);

  useEffect(() => {
    updateSize();
  }, []);

  function updateSize() {
    setIsMobile(window.innerWidth <= DeviceSizes.mobile);
    setIsTabletSmall(
      window.innerWidth > DeviceSizes.mobile && window.innerWidth <= DeviceSizes.tablet_small,
    );
    setIsTabletLarge(
      window.innerWidth > DeviceSizes.tablet_small && window.innerWidth <= DeviceSizes.tablet_large,
    );
  }

  return {
    isTabletSmall: isTabletSmall,
    isTabletLarge: isTabletLarge,
    isMobile: isMobile,
    windowsWidth: window.innerWidth,
    windowsHeight: window.innerHeight,
    desktopSize: DeviceSizes.desktop,
    tabletLargeSize: DeviceSizes.tablet_large,
    tabletSmallSize: DeviceSizes.tablet_small,
    mobileSize: DeviceSizes.mobile,
    mobileSmallSize: DeviceSizes.mobile_small,
  };
};
