import React from 'react';
import { WorkTypeRepository } from '../../../../domain/worktype/WorkTypeRepository';
import { ExtensionRepository } from '../../../../domain/worktype/extension/ExtensionRepository';
import {
  updateExtensionUseCase,
  updateTeethExtensionUseCase,
} from '../../../../useCases/worktype/extension/updateExtensionUseCase';
import UpdateWorkTypeExtensionInfo from '../../../../domain/worktype/extension/UpdateWorkTypeExtensionInfo';
import UpdateTeethTypeExtensionInfo from '../../../../domain/worktype/extension/UpdateTeethTypeExtensionInfo';
import ExtensionInfo from '../../../ui-model/worktype/extension/ExtensionInfo';
import Extension from '../../../ui-model/worktype/extension/Extension';
import useTranslate from '../../../translations/useTranslate';
import { ExtensionList } from '../../../../domain/worktype/extension/Extension';

function useExtensionViewModel(workTypeRepo: WorkTypeRepository, repo: ExtensionRepository) {
  const translate = useTranslate();

  const getSubategoryByWorkTypeId = (id: number): ExtensionInfo[] | [] => {
    let extensionSubcategory: ExtensionInfo[] = [];
    workTypeRepo.workTypes
      ?.filter((wt) => wt.id === id)
      .forEach((wt) => {
        extensionSubcategory = [...getExtensions(wt.extensionList)];
      });
    return extensionSubcategory;
  };

  const getTeethExtensionsByWorkTypeId = (id: number): ExtensionInfo[] | [] => {
    let teethExtensions: ExtensionInfo[] = [];
    workTypeRepo.workTypes
      ?.filter((wt) => wt.id === id)
      .forEach((wt) => {
        teethExtensions = [...getTeethExtensions(wt.teethTypeList)];
      });
    return teethExtensions;
  };

  const getExtensions = (extensionList: ExtensionList): ExtensionInfo[] => {
    const extensionsList: ExtensionInfo[] = [];

    extensionList !== null &&
      extensionList.forEach((ext) => {
        const newExtList: Array<Extension> = [];
        ext.extensions.forEach((extension) => {
          newExtList.push(
            new Extension(
              extension.id,
              translate(extension.name),
              extension.price,
              extension.active ? true : false,
              extension.isTeeth,
              [],
            ),
          );
        });
        extensionsList.push(new ExtensionInfo(ext.id, newExtList));
      });
    return extensionsList.map((ext) => ({
      ...ext,
      extensions: ext.extensions.map((extension) => ({
        ...extension,
        name: translate(extension.name),
      })),
    }));
  };

  const getTeethExtensions = (teethTypeList): ExtensionInfo[] => {
    const teethExtensionsList: ExtensionInfo[] = [];
    const teethExtesions: Array<Extension> = [];
    let teethGroupId = -1;
    teethTypeList !== null &&
      teethTypeList.forEach((teethExtension) => {
        teethExtension.extensionList?.forEach((extension) => {
          extension.extensions.forEach((ext) => {
            const newExt = new Extension(
              ext.id,
              translate(ext.name),
              ext.price,
              ext.active === 1 ? true : false,
              ext.isTeeth,
              getTeethIds(ext.id, teethTypeList),
            );
            if (!teethExtesions.some((item) => item.id === ext.id)) {
              teethExtesions.push(newExt);
              teethGroupId = extension.id;
            }
          });
        });
      });
    if (teethGroupId !== -1) {
      teethExtensionsList.push(new ExtensionInfo(teethGroupId, teethExtesions));
    }
    return teethExtensionsList;
  };

  const getTeethIds = (extensionId, teethTypeList): number[] => {
    const teethIdsList: number[] = [];
    teethTypeList.forEach((teethExtension) => {
      teethExtension.extensionList?.forEach((extension) => {
        if (extension.extensions.some((item) => extensionId === item.id)) {
          if (!teethIdsList.includes(teethExtension.id)) teethIdsList.push(teethExtension.id);
        }
      });
    });
    return teethIdsList;
  };

  const updateExtension = React.useCallback(
    function (workTypeId: number, groupId: number, extension: Extension) {
      if (extension.isTeeth) {
        updateTeethExtensionUseCase(
          {
            updateExtension: repo.updateExtension,
            updateTeethExtension: repo.updateTeethExtension,
          },
          new UpdateTeethTypeExtensionInfo(
            workTypeId,
            extension.id,
            extension.active ? 1 : 0,
            extension.price,
          ),
        );
      } else {
        updateExtensionUseCase(
          {
            updateExtension: repo.updateExtension,
            updateTeethExtension: repo.updateTeethExtension,
          },
          new UpdateWorkTypeExtensionInfo(
            workTypeId,
            groupId,
            extension.id,
            extension.active ? 1 : 0,
            extension.price,
          ),
        );
      }
    },
    [repo.updateExtension],
  );

  return {
    getSubategoryByWorkTypeId,
    getTeethExtensionsByWorkTypeId,
    updateExtension,
  };
}

export { useExtensionViewModel };
