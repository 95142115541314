import {
  Credentials,
  LoggedCompanyData,
  LoginResponse,
  PasswordData,
} from '../../domain/authenticationFlow/AuthDataTypes';
import { checkErrorStatus } from '../ErrorHandling';
import { RESET_LABOR_DATA } from '../generalActionTypes';
import * as actionTypes from './authActionTypes';
import { addUser, handleLogin, resetPassword, setPassword, updateUser } from './authService';

const handleLoginAction = (c: Credentials) => (dispatch: any) => {
  return handleLogin(c).then((loginResp) => {
    if (loginResp) {
      const currentUrl = localStorage.getItem('redirectAfterLogin');
      dispatch(actionTypes.loginSuccess(loginResp as LoginResponse));
      return { result: true, currentUrl: currentUrl };
    }
    return { result: true, currentUrl: null };
  });
};

const updateUserAction = (data) => (dispatch: any) => {
  return updateUser(data).then((resp) => {
    if (resp.data !== null && checkErrorStatus(resp.code, dispatch)) {
      dispatch(actionTypes.updateUserSuccess(resp.data));
      return { hasError: false, errorCode: resp.code, errorMessage: '', response: true };
    }
    return { hasError: true, errorCode: resp.code, errorMessage: resp.message, response: false };
  });
};

const logoutAction = (dispatch) => {
  dispatch(actionTypes.logout());
  return true;
};

const startSetLaboratoryAction = (dispatch) => {
  dispatch({ type: actionTypes.SET_LABORATORY_START });
};

const setLaboratoryAction = (companyData: LoggedCompanyData | null) => (dispatch) => {
  setTimeout(() => {
    if (companyData === null) {
      dispatch({ type: RESET_LABOR_DATA });
    }
    dispatch(actionTypes.setLaboratory(companyData));
  });
  return true;
};

const addUserAction = (username: string, isEnabledMailList: boolean) => (dispatch: any) => {
  dispatch({ type: actionTypes.ADD_USER });
  return addUser({ email: username, isEnabledMailList: isEnabledMailList }).then((resp) => {
    if (checkErrorStatus(resp.code, dispatch)) {
      dispatch({ type: actionTypes.ADD_USER_SUCCESS });
      return { hasError: false, errorCode: resp.code, response: true };
    }
    dispatch({ type: actionTypes.ADD_USER_FAILD });
    return { hasError: true, errorCode: resp.code, response: false };
  });
};

const setPasswordAction = (password: PasswordData, isRecover: boolean) => (dispatch: any) => {
  dispatch({ type: actionTypes.SET_PASSWORD });
  return setPassword(password).then((resp) => {
    if (checkErrorStatus(resp.code, dispatch)) {
      dispatch({ type: actionTypes.SET_PASSWORD_SUCCESS });
      if (isRecover) {
        return { hasError: false, errorCode: resp.code, response: true };
      } else {
        dispatch(
          actionTypes.loginSuccess({
            toke: resp.toke,
            userId: resp.userId,
            email: resp.email,
            language: resp.language,
            firstName: resp.firstName,
            lastName: resp.lastName,
            phoneNumber: resp.phoneNumber,
            profileColor: resp.profileColor,
            isFtiFinished: resp.isFtiFinished,
          }),
        );
        dispatch({ type: actionTypes.AUTHENTICATE_USER_SUCCESS, session: resp.toke });
      }
      return { hasError: false, errorCode: resp.code, response: true };
    }
    dispatch({ type: actionTypes.SET_PASSWORD_FAILD, error: 11 });
    return { hasError: true, errorCode: resp.code, response: false };
  });
};

const resetPasswordAction = (email: string) => (dispatch: any) => {
  dispatch({ type: actionTypes.RESET_PASSWORD });
  return resetPassword({ email }).then((resp) => {
    if (checkErrorStatus(resp.code, dispatch)) {
      dispatch({ type: actionTypes.RESET_PASSWORD_SUCCESS });
      return { hasError: false, errorCode: resp.code, response: true };
    }
    dispatch({ type: actionTypes.RESET_PASSWORD_FAILD, error: 11 });
    return { hasError: true, errorCode: resp.code, response: false };
  });
};

export {
  handleLoginAction,
  updateUserAction,
  logoutAction,
  startSetLaboratoryAction,
  setLaboratoryAction,
  addUserAction,
  setPasswordAction,
  resetPasswordAction,
};
