import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../icons/trash.svg';
import { ReactComponent as DotsIcon } from '../../icons/dots.svg';
import { ReactComponent as EmailIcon } from '../../icons/email.svg';
import { ReactComponent as MapIcon } from '../../icons/mapIcon.svg';
import { ReactComponent as DropdownIcon } from '../../icons/arrowDown.svg';
import { Text, TextType } from '../components/dsm/Text';
import { ContextMenu, ContextMenuOffset, ContextMenuOption } from '../components/dsm/ContextMenu';
import { CollaboratorItem } from './CollaboratorItem';
import Avatar from '../components/generic/Avatar';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import useTranslate from '../translations/useTranslate';

export type UserManagementRowProps = {
  itemModel: any;
  currentUserId: number;
  isWorker: boolean;
  onDelete: (id: number, isContact: boolean) => void;
  onEdit: (itemModel: any, isWorker: boolean, isEdit: boolean) => void;
};

const MAX_COLLABORATORS = 9;

export const UserManagementRow: FC<UserManagementRowProps> = ({
  itemModel,
  currentUserId,
  isWorker,
  onDelete,
  onEdit,
}) => {
  const translate = useTranslate();
  const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false);
  const [contextMenuOffset, setContextMenuOffset] = useState<ContextMenuOffset>({ x: 0, y: 0 });
  const [isActiveDropDown, setIsActiveDropDown] = useState<boolean>(false);

  const ContextMenuOptions: Array<ContextMenuOption> = [
    {
      key: 0,
      icon: <EditIcon />,
      label: translate(LANGUAGE_KEYS.EDIT),
      onClick: () => {
        onHandleEdit(false);
      },
    },
    {
      key: 1,
      icon: <DeleteIcon />,
      label: translate(LANGUAGE_KEYS.DELETE),
      onClick: () => {
        onHandleDelete();
      },
    },
    {
      key: 2,
      icon: <CustomEmailIcon />,
      label:
        itemModel.roleId < 0
          ? translate(LANGUAGE_KEYS.RESEND_INVITE)
          : translate(LANGUAGE_KEYS.SEND_INVITE),
      onClick: () => {
        onHandleEdit(true);
      },
    },
  ];

  const handleContextMenuClick = (offset: ContextMenuOffset) => {
    setIsContextMenuOpen(true);
    setContextMenuOffset(offset);
  };

  const onHandleDelete = () => {
    onDelete(itemModel.id, itemModel.isContact);
  };

  const onHandleEdit = (editInvite: boolean) => {
    onEdit(itemModel, isWorker, editInvite);
  };

  const renderClientsOrWorkerRow = (user: any) => {
    return (
      <>
        <SimpleUserFirstRowContainer>
          <Avatar
            fullName={user.avatar}
            size={{ outer: 24, inner: 22 }}
            avatarStyle={{ background: user.profileColor }}
          />
          <Name type={TextType.BODY_2_BOLD}>{user.name}</Name>
        </SimpleUserFirstRowContainer>
        <SimpleUserSecondRowContainer>
          <EmailComponent href={`mailto:${user.email}`}>
            <Text type={TextType.BODY_2} ellipsis={true}>
              {user.email}
            </Text>
          </EmailComponent>
        </SimpleUserSecondRowContainer>
        <SimpleUserThirdRowContainer>
          <Text type={TextType.BODY_2}>{user.phone}</Text>
        </SimpleUserThirdRowContainer>
        <SimpleUserFourthRowContainer>
          {user.roleId !== null && user.roleId < 0 && (
            <Text className='color-gray' type={TextType.BODY_2}>
              {translate(LANGUAGE_KEYS.INVITATION_SENT)}
            </Text>
          )}
          {user.roleId !== null && user.roleId > 0 && (
            <Text type={TextType.BODY_2_BOLD}>{translate(user.roleName)}</Text>
          )}
          {user.companiesName !== undefined &&
            user.companiesName !== null &&
            user.companiesName.length > 0 && (
              <CompaniesConatinerContainer>
                <FirstComapnyDetail
                  tabIndex={0}
                  className={`${user.companiesName.length > 1 && 'cursor'}`}
                  onClick={() => setIsActiveDropDown(!isActiveDropDown)}
                  onBlur={() => {
                    setIsActiveDropDown(false);
                  }}
                >
                  <MapIcon width={20} height={20} />
                  <Text className='text' type={TextType.BODY_2} ellipsis={true}>
                    {user.companiesName[0]}
                  </Text>
                  {user.companiesName.length > 1 && (
                    <>
                      <PlusCompanyText type={TextType.BODY_2} ellipsis={true}>
                        +{user.companiesName.length - 1}
                      </PlusCompanyText>
                      <DropdownIconStyled className={`${isActiveDropDown && 'open'}`} />
                    </>
                  )}
                </FirstComapnyDetail>
                {user.companiesName.length > 1 && (
                  <CompanyDropDownContent className={`${isActiveDropDown && 'dropdown-active'}`}>
                    {user.companiesName.map((name, index) => {
                      return (
                        <Text key={index} type={TextType.BODY_2}>
                          {name}
                        </Text>
                      );
                    })}
                  </CompanyDropDownContent>
                )}
              </CompaniesConatinerContainer>
            )}
        </SimpleUserFourthRowContainer>
        <SimpleUserFifthRowContainer>
          {itemModel.id !== currentUserId && (
            <>
              <IconComponent
                onClick={(e) => {
                  e.preventDefault();
                  handleContextMenuClick({ x: e.clientX, y: e.clientY + 10 });
                }}
              >
                <DotsIcon />
              </IconComponent>
              <ContextMenuStyle
                isOpen={isContextMenuOpen}
                setIsOpen={setIsContextMenuOpen}
                options={
                  !isWorker
                    ? ContextMenuOptions.filter(
                        (item) => item.label !== 'Send invite' && item.label !== 'Resend invite',
                      )
                    : itemModel.roleId > 0
                    ? ContextMenuOptions.filter(
                        (item) => item.label !== 'Send invite' && item.label !== 'Resend invite',
                      )
                    : ContextMenuOptions
                }
                offset={contextMenuOffset}
                className='context'
                textType={TextType.CAPTION_BOLD}
              />
            </>
          )}
        </SimpleUserFifthRowContainer>
      </>
    );
  };

  const renderCollaboratorsSection = (collaborators) => {
    if (collaborators.length > 0) {
      return collaborators?.map((coll, index) => {
        return (
          <CollaboratorItem
            key={index}
            collaborator={coll}
            index={index}
            maxCollaboratorsSize={MAX_COLLABORATORS}
            collaboratorsSize={collaborators.length}
            collaboratorsList={collaborators
              .slice(MAX_COLLABORATORS - 1, collaborators.length - 1)
              .map((c) => {
                return {
                  icon: (
                    <Avatar
                      fullName={c.name}
                      size={{ outer: 24, inner: 22 }}
                      avatarStyle={{ background: c.profileColor }}
                    />
                  ),
                  label: c.name,
                };
              })}
          />
        );
      });
    }
  };

  const renderCompanyRow = (company: any) => {
    return (
      <>
        <CompanyFirstRowContainer>
          <Name type={TextType.BODY_2_BOLD}>{company.name}</Name>
        </CompanyFirstRowContainer>
        <CompanySecondRowContainer>
          <Text type={TextType.BODY_2}>{company.address}</Text>
        </CompanySecondRowContainer>
        <CompanyThirdRowContainer>
          {renderCollaboratorsSection(company.collaborators)}
        </CompanyThirdRowContainer>
        <CompanyFourthRowContainer>
          <IconComponent
            onClick={(e) => {
              e.preventDefault();
              handleContextMenuClick({ x: e.clientX, y: e.clientY + 10 });
            }}
          >
            <DotsIcon />
          </IconComponent>
          <ContextMenuStyle
            isOpen={isContextMenuOpen}
            setIsOpen={setIsContextMenuOpen}
            options={ContextMenuOptions}
            offset={contextMenuOffset}
            className='context'
          />
        </CompanyFourthRowContainer>
      </>
    );
  };

  return (
    <WorkTypeRowContainer>
      {itemModel.isContact && renderClientsOrWorkerRow(itemModel)}
      {!itemModel.isContact && renderCompanyRow(itemModel)}
    </WorkTypeRowContainer>
  );
};

const WorkTypeRowContainer = styled.div`
  display: flex;
  align-self: stretch;
  flex-grow: 0;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px 16px 24px;
  background: var(--dts_white);
  border-radius: 3px;
  margin-bottom: 8px;
  gap: 24px;
`;

const SimpleUserFirstRowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 0 0 calc(25% - 24px);
  width: calc(25% - 24px);
  max-width: calc(25% - 24px);
`;

const Name = styled(Text)`
  width: 100%;
  max-width: 100%;
`;

const SimpleUserSecondRowContainer = styled.div`
  flex: 0 0 calc(23% - 24px);
  width: calc(23% - 24px);
  max-width: calc(23% - 24px);
`;

const EmailComponent = styled.a`
  text-decoration: none;
  display: inline-block;
  max-width: 100%;
  p {
    color: var(--dts_default_blue);
  }
  &:hover {
    p {
      color: var(--dts_hover_blue);
    }
  }
`;

const SimpleUserThirdRowContainer = styled.div`
  flex: 0 0 calc(12% - 24px);
  width: calc(12% - 24px);
  max-width: calc(12% - 24px);
`;

const SimpleUserFourthRowContainer = styled.div`
  flex: 0 0 calc(35% - 24px);
  width: calc(35% - 24px);
  max-width: calc(35% - 24px);
  .color-gray {
    color: var(--dts_dark_grey);
  }
`;

const SimpleUserFifthRowContainer = styled.div`
  flex-grow: 1;
  position: relative;
`;

const IconComponent = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: flex-end;
  padding: 10px 0px;
  svg {
    flex-shrink: 0;
  }
  :hover {
    svg {
      path {
        fill: var(--dts_hover_blue);
      }
    }
  }
`;

const ContextMenuStyle = styled(ContextMenu)`
  min-width: 137px;
  &.context {
    position: absolute;
    right: 0;
    top: 15px !important;
    left: unset !important;
  }
  svg {
    path {
      color: var(--dts_black);
    }
  }
`;

const CompanyFirstRowContainer = styled.div`
  flex: 0 0 calc(25% - 24px);
  width: calc(25% - 24px);
  max-width: calc(25% - 24px);
`;

const CompanySecondRowContainer = styled.div`
  flex: 0 0 calc(26% - 24px);
  width: calc(26% - 24px);
  max-width: calc(26% - 24px);
`;

const CompanyThirdRowContainer = styled.div`
  flex: 0 0 calc(45% - 24px);
  width: calc(45% - 24px);
  max-width: calc(45% - 24px);
  display: flex;
  flex-direction: row;
  gap: 1px;
`;

const CompanyFourthRowContainer = styled.div`
  flex-grow: 1;
  position: relative;
`;

const CustomEmailIcon = styled(EmailIcon)`
  fill: var(--dts_black);
`;

const CompaniesConatinerContainer = styled.div`
  max-width: 172px;
  min-width: 172px;
  position: relative;
  display: inline-block;
`;

const CompanyDropDownContent = styled.div`
  display: none;
  position: absolute;
  background-color: var(--dts_white);
  min-width: 130px;
  max-width: 130px;
  box-shadow: -1px 2px 11px 0px #00000024;
  padding: 16px;
  z-index: 1;
  border-radius: 3px;
  margin-top: 2px;
  left: 10px;
  &.dropdown-active {
    display: block;
  }
  p {
    padding: 6px 8px 6px 8px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
  }
`;

const FirstComapnyDetail = styled.div`
  max-width: 172px;
  display: flex;
  align-items: center;
  gap: 8px;
  &.cursor {
    cursor: pointer;
  }
  p.text {
    flex-grow: 1;
  }
  svg {
    flex-shrink: 0;
  }
`;

const PlusCompanyText = styled(Text)`
  flex-shrink: 0;
`;

const DropdownIconStyled = styled(DropdownIcon)`
  fill: var(--dts_dark);
  &.open {
    transform: rotate(180deg);
  }
`;
