import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as LeftIcon } from '../../../icons/leftIcon.svg';
import { Text, TextType } from '../dsm/Text';
import { DeviceSizeProps } from '../../DeviceInformations';
import { useDeviceParameters } from '../../hooks/useDeviceParameters';

export type BreadcrumbProps = {
  textType: TextType;
  iconWidth?: number;
  iconHeight?: number;
  breadcrumbList: Array<BreadcrumbItem>;
};

export type BreadcrumbItem = {
  name: string;
  link: string;
  state?: any;
};

function Breadcrumb(breadcrumbProps: BreadcrumbProps) {
  const { textType, iconWidth, iconHeight, breadcrumbList } = breadcrumbProps;
  const navigate = useNavigate();
  const { mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();
  return (
    <BreadcrumbContainer
      mobile_small={mobileSmallSize}
      mobile={mobileSize}
      tablet_small={tabletSmallSize}
      tablet_large={tabletLargeSize}
      desktop={desktopSize}
    >
      <BreadcrumbContent>
        {breadcrumbList?.map((breadcrumb, index) => {
          return breadcrumb.link !== undefined && breadcrumb.link !== '' ? (
            <BreadcrumbContentLink
              key={index}
              onClick={() => {
                navigate(breadcrumb.link, { state: breadcrumb.state });
              }}
            >
              <Text type={textType === undefined ? TextType.BODY_BOLD : textType} ellipsis={true}>
                {breadcrumb.name}
              </Text>
            </BreadcrumbContentLink>
          ) : (
            <BreadcrumbContent key={index}>
              <SVGcontainer>
                <LeftIcon
                  width={iconWidth === undefined ? '12.35' : iconWidth}
                  height={iconHeight === undefined ? '20' : iconHeight}
                />
              </SVGcontainer>
              <Text type={textType === undefined ? TextType.BODY_BOLD : textType} ellipsis={true}>
                {breadcrumb.name}
              </Text>
            </BreadcrumbContent>
          );
        })}
      </BreadcrumbContent>
    </BreadcrumbContainer>
  );
}

const BreadcrumbContainer = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
 
    //Desktop
    @media (min-width: ${desktop}px) {
     margin-top: 40px;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const BreadcrumbContent = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  flex-wrap: wrap;
`;

const BreadcrumbContentLink = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  flex-wrap: wrap;
  text-decoration: none;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const SVGcontainer = styled.p`
  line-height: 0px;
  margin: 0;
`;

export default Breadcrumb;
