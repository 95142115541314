import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import { SearchAndAddNew } from '../../../components/dsm/SearchAndAddNew';
import EmptyView from '../../../components/generic/EmptyView';
import { TemplateRow } from '../components/worktypeitem/templates/TemplateRow';
import TemplateRowHeader from '../components/worktypeitem/templates/TemplateRowHeader';
import { useTemplatesViewModel } from './TemplatesViewModel';
import { useTemplateRepositoryImplementation } from '../../../../data/worktype/templates/TemplateRepositoryImplementation';
import { TemplateUiModel } from '../../../ui-model/worktype/TemplateUiModel';
import useTranslate from '../../../translations/useTranslate';
import { LANGUAGE_KEYS } from '../../../translations/languageKeys';

function Templates() {
  const translate = useTranslate();
  const worktypeId = useParams().id;
  const templatesRepo = useTemplateRepositoryImplementation();

  const {
    getSortTemplatesByWorkTypeId,
    newTemplate,
    duplicateTemplate,
    deleteTemplate,
    updateTemplate,
    updateTemplateOrderOrActive,
  } = useTemplatesViewModel(templatesRepo);

  const templates: TemplateUiModel[] = getSortTemplatesByWorkTypeId(Number(worktypeId));

  // If we not use a copy list of templatesCopy then in case of drag and drop, the elements will jump
  const [templatesCopy, setTemplatesCopy] = useState<TemplateUiModel[]>(templates);
  const [useTemplatesCopy, setUseTemplatesCopy] = useState<boolean>(false);

  // This value helps to perform scrolling. It will indicate you after ui rerender whether to scroll to the bottom of the page or not
  const [addTemplateFlag, setAddTemplateFlag] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (addTemplateFlag) {
      scrollToBottom();
      setAddTemplateFlag(false);
    }
  });

  const scrollToBottom = () => {
    const lastChildElement = ref.current?.lastElementChild;
    lastChildElement?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleDragDrop = (results) => {
    const { source, destination, type } = results;
    if (!destination) return;
    if (source.droppableId === destination.droppableId && source.index === destination.index)
      return;
    if (type === 'group') {
      const reorderedTemplatesList = templates;
      const templatesSourceIndex = source.index;
      const templatesDestinatonIndex = destination.index;
      const [removedTeeth] = reorderedTemplatesList.splice(templatesSourceIndex, 1);
      reorderedTemplatesList.splice(templatesDestinatonIndex, 0, removedTeeth);
      sortTemplatesList(reorderedTemplatesList!);
      setTemplatesCopy(reorderedTemplatesList!);
      updateTemplateOrderOrActive(reorderedTemplatesList);
      setUseTemplatesCopy(true);
    }
  };

  const addNewTemplate = () => {
    newTemplate({
      name: emptyTemplate.name,
      workTypeId: Number(worktypeId),
      phases: [],
    });
    setUseTemplatesCopy(false);
    setAddTemplateFlag(true);
  };

  const onDeleteTemplate = (template: TemplateUiModel) => {
    deleteTemplate(template.id);
    setUseTemplatesCopy(false);
  };

  const onDuplicateTemplate = (template: TemplateUiModel) => {
    duplicateTemplate(template.id);
    setAddTemplateFlag(true);
  };

  const onHandleText = (template: TemplateUiModel, name: string) => {
    template.name = name;
    updateTemplate(template);
  };

  const onHandleChangeSearchBar = (e) => {
    if (e.target.value.length <= 0) {
      setUseTemplatesCopy(false);
    } else {
      const list: any = templates.filter((item) => {
        if (item.name.toUpperCase().includes(e.target.value.toUpperCase())) {
          return item;
        }
      });
      setUseTemplatesCopy(true);
      setTemplatesCopy(list);
    }
  };

  const onClickSearchClose = () => {
    setUseTemplatesCopy(false);
  };

  const emptyTemplate = new TemplateUiModel(
    templates !== null ? templates.length : 0,
    translate(LANGUAGE_KEYS.EMPTY_TEMPLATE_NAME),
    templates !== null ? templates.length + 1 : 1,
    1,
    0,
    [],
    Number(worktypeId),
  );

  // Functions
  function sortTemplatesList(templates: TemplateUiModel[]) {
    return templates.map((template, index) => {
      template.order = index;
    });
  }

  return (
    <div style={{ marginTop: 24 }}>
      <SearchAndAddNew
        handleChangeSearchBar={onHandleChangeSearchBar}
        clickSearchClose={onClickSearchClose}
        addNewText={translate(LANGUAGE_KEYS.ADD_NEW_TEMPLATE)}
        addNew={addNewTemplate}
        isWorker={false}
      />
      {(templates.length > 0 && !useTemplatesCopy) ||
      (useTemplatesCopy && templatesCopy !== null && templatesCopy.length > 0) ? (
        <>
          <TemplateRowHeader />
          <DragDropContext onDragEnd={handleDragDrop}>
            <Droppable droppableId='ROOT' type='group'>
              {(provided) => (
                <DroppableProvide {...provided.droppableProps} ref={provided.innerRef}>
                  <div ref={ref}>
                    <TemplatesList
                      templatesList={
                        useTemplatesCopy && templatesCopy !== null && templatesCopy.length > 0
                          ? templatesCopy
                          : templates
                      }
                      onDeleteTemplate={onDeleteTemplate}
                      onDuplicateTemplate={onDuplicateTemplate}
                      onHandleText={onHandleText}
                    />
                  </div>
                  {provided.placeholder}
                </DroppableProvide>
              )}
            </Droppable>
          </DragDropContext>
        </>
      ) : (
        <EmptyView allHeightOtherItems={240} />
      )}
    </div>
  );
}

function TemplatesList(props: {
  templatesList: TemplateUiModel[] | null;
  onDeleteTemplate: (template: TemplateUiModel) => void;
  onDuplicateTemplate: (template: TemplateUiModel) => void;
  onHandleText: (template: TemplateUiModel, name: string) => void;
}) {
  return (
    <>
      {props.templatesList !== null &&
        buildTemplatesList(
          props.templatesList.filter((value) => {
            return value;
          }),
          props.onDeleteTemplate,
          props.onDuplicateTemplate,
          props.onHandleText,
        )}
    </>
  );
}

function buildTemplatesList(
  templatesList: TemplateUiModel[] | null,
  onDeleteTemplate: (template: TemplateUiModel) => void,
  onDuplicateTemplate: (template: TemplateUiModel) => void,
  onHandleText: (template: TemplateUiModel, name: string) => void,
) {
  const templatesRowView: any[] = [];
  templatesList?.map(function (t, i) {
    templatesRowView.push(
      <TemplateRow
        templateModel={t}
        index={i}
        key={t.id}
        onDeleteTemplate={onDeleteTemplate}
        onDuplicateTemplate={onDuplicateTemplate}
        onHandleText={onHandleText}
      />,
    );
  });
  return templatesRowView;
}

const DroppableProvide = styled.div``;

export default Templates;
