import React from 'react';
import styled from 'styled-components';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';
import Breadcrumb, { BreadcrumbItem } from '../../../components/generic/Breadcrumb';
import Extensions from './Extensions';
import Teeth from './Teeth';
import Templates from './Templates';
import { useWorktypesViewModel } from '../WorktypesViewModel';
import { useWorkTypeRepositoryImplementation } from '../../../../data/worktype/WorkTypeRepositoryImplementation';
import ToggleHeader from '../../../components/generic/ToggleHeader';
import { useDeviceParameters } from '../../../hooks/useDeviceParameters';
import ContentLayout from '../../../components/layout/ContentLayout';
import useTranslate from '../../../translations/useTranslate';
import { LANGUAGE_KEYS } from '../../../translations/languageKeys';
import { TextType } from '../../../components/dsm/Text';
import { useAuth } from '../../../../routeFiles/AuthContext';
import { RoundButton, RoundButtonState } from '../../../components/dsm/buttons/RoundButton';

function WorktypeItemContentBodyHeader(props: {
  worktypeItemId: string | null;
  worktypeName: string | null;
}) {
  const location = useLocation();
  const translate = useTranslate();
  const navigate = useNavigate();
  const { companyId } = useAuth();
  const { isTabletSmall, isTabletLarge, isMobile } = useDeviceParameters();

  const breadcrumbList: BreadcrumbItem[] = [
    {
      name: translate(LANGUAGE_KEYS.WORKTYPES),
      link: `/${companyId}/settings/worktype`,
    },
    { name: props.worktypeName === null ? '' : props.worktypeName, link: '' },
  ];

  return (
    <>
      <ContentBodyHeader>
        <HeaderTopConatiner>
          <Breadcrumb
            textType={
              !isTabletSmall && !isTabletLarge && !isMobile
                ? TextType.TITLE_BOLD_4
                : TextType.BODY_BOLD
            }
            breadcrumbList={breadcrumbList}
          />
        </HeaderTopConatiner>

        <WorktypeItemTabs>
          <RoundButton
            onClick={() =>
              navigate(`/${companyId}/settings/worktype/${props.worktypeItemId}/extensions`)
            }
            indicator={false}
            buttonState={
              `/${companyId}/settings/worktype/${props.worktypeItemId}/extensions` ===
              location.pathname
                ? RoundButtonState.ACTIVE
                : RoundButtonState.DEFAULT
            }
            textType={TextType.BODY_2_BOLD}
          >
            {translate(LANGUAGE_KEYS.EXTENSIONS)}
          </RoundButton>
          <RoundButton
            onClick={() =>
              navigate(`/${companyId}/settings/worktype/${props.worktypeItemId}/teeth`)
            }
            indicator={false}
            buttonState={
              `/${companyId}/settings/worktype/${props.worktypeItemId}/teeth` === location.pathname
                ? RoundButtonState.ACTIVE
                : RoundButtonState.DEFAULT
            }
            textType={TextType.BODY_2_BOLD}
          >
            {translate(LANGUAGE_KEYS.TEETH)}
          </RoundButton>
          <RoundButton
            onClick={() =>
              navigate(`/${companyId}/settings/worktype/${props.worktypeItemId}/templates`)
            }
            indicator={false}
            buttonState={
              `/${companyId}/settings/worktype/${props.worktypeItemId}/templates` ===
              location.pathname
                ? RoundButtonState.ACTIVE
                : RoundButtonState.DEFAULT
            }
            textType={TextType.BODY_2_BOLD}
          >
            {translate(LANGUAGE_KEYS.TEMPLATES)}
          </RoundButton>
        </WorktypeItemTabs>
      </ContentBodyHeader>
    </>
  );
}

function WorktypeItem() {
  const { id } = useParams();
  const repo = useWorkTypeRepositoryImplementation();
  const { getWorkTypeNameById } = useWorktypesViewModel(repo);
  const { isTabletSmall, isTabletLarge, isMobile } = useDeviceParameters();

  return (
    <>
      {(isTabletSmall || isTabletLarge || isMobile) && (
        <ToggleHeader
          hasContent={false}
          title={''}
          enabledClick={undefined}
          buttonText={undefined}
          onClick={() => {}}
          buttonIcon={undefined}
          noButtonDesign={false}
        />
      )}
      <ContentLayout>
        <WorktypeItemContentBodyHeader
          worktypeItemId={id === undefined ? null : id}
          worktypeName={getWorkTypeNameById(Number(id))}
        />
        <Routes>
          <Route path='/extensions' element={<Extensions />} />
          <Route path='/teeth' element={<Teeth />} />
          <Route path='/templates' element={<Templates />} />
        </Routes>
      </ContentLayout>
    </>
  );
}

const ContentBodyHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const HeaderTopConatiner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const WorktypeItemTabs = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;
`;

export default WorktypeItem;
